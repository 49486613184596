( function( $, window, document, undefined ) {

	//var navigation = '';
	// Check JavaScript is available.
	//if ( element.classList.contains( 'js' ) ) {
	//}

	// Add `.is-closed` modifier to navigation on page load.
	$( document ).ready(function() {
		$( '.c-menu' ).toggleClass( 'is-active' );
	});

	$( document ).ready(function() {

		$( '.js-menu-navigation-primary, [data-js="site-menu-close"]' ).click(function(event) {
			$( '.c-menu' ).toggleClass( 'is-active' );
			$( 'body' ).toggleClass( 'has-menu' );
			event.preventDefault();
		});
	});


	/**
	 * Toggle Navigtion Sub Menu
	 */
	$(document).ready(function () {
		console.log('loaded');
		$('[data-nav-toggle]').click(function (event) {
			console.log('clicked:', $(this).parent('li').find('.sub-menu'));
			event.preventDefault();

			if ($(this).parent('li').find('.sub-menu').hasClass('is_active')) {
				// This submenu is already open.
				$(this).addClass('is_closed');
				$(this).removeClass('is_open');
				$(this).parent('li').find('.sub-menu').removeClass('is_active');

			} else {
				$( '.sub-menu.is_active' ).removeClass( 'is_active' );
				//$( '.c-menu__button' ).addClass('is_closed');
				//$( '.c-menu__button' ).removeClass('is_open');
				$(this).parent('li').find('.sub-menu').addClass('is_active');
				$(this).removeClass( 'is_closed' );
				$(this).addClass( 'is_open' );

			}

		});
	});
})( jQuery, window, document );
