/**
 * Scroll function
 * Courtesy of Scott Dowding.
 * Link: http://stackoverflow.com/questions/487073/check-if-element-is-visible-after-scrolling
 */
( function( $, window, document, undefined ) {

	$(document).ready(function() {

		// Check if element is scrolled into view.
		function isScrolledIntoView(el) {
			var rect = el.getBoundingClientRect();
			var elemTop = rect.top;
			var elemBottom = rect.bottom;

			// Only completely visible elements return true:
			var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
			// Partially visible elements return true:
			isVisible = elemTop < window.innerHeight && elemBottom >= 0;
			return isVisible;
	}

		/*
		* Example Code:
		* If element is scrolled into view, fade it in.
		*
			$(window).scroll(function() {
				$(".scroll-animations .animated").each(function() {
					if (isScrolledIntoView(this) === true) {
						$(this).addClass("fadeInLeft");
					}
				});
			});
		*/

		$( '.is-style-hero img' ).each(function() {
			if ( isScrolledIntoView(this) === true ) {
				$(this).addClass( 'animatetl' );
			}
		});

		$( '.is-style-hero h1' ).each(function() {
			if ( isScrolledIntoView(this) === true ) {
				$(this).addClass( 'animatelt' );
			}
		});

		$( '.wp-block-image img' ).each(function() {
			if ( isScrolledIntoView(this) === true ) {
				$(this).addClass( 'animatetl' );
			}
		});
		$( '.wp-block-media-text__media img' ).each(function() {
			if ( isScrolledIntoView(this) === true ) {
				$(this).addClass( 'animatetl' );
			}
		});
		/* $( 'h2.c-heading' ).each(function() {
			if ( isScrolledIntoView(this) === true ) {
				$(this).addClass( 'animatelt' );
			}
		});
		$( 'h3.c-heading' ).each(function() {
			if ( isScrolledIntoView(this) === true ) {
				$(this).addClass( 'animatelt' );
			}
		});
		$( 'section.c-link' ).each(function() {
			if ( isScrolledIntoView(this) === true ) {
				$(this).addClass( 'animatetl' );
			}
		}); */

		$(window).scroll(function() {
			$( '.is-style-hero img' ).each(function() {
				if ( isScrolledIntoView(this) === true ) {
					$(this).addClass( 'animatetl' );
				}
			});

			$( '.is-style-hero h1' ).each(function() {
				if ( isScrolledIntoView(this) === true ) {
					$(this).addClass( 'animatelt' );
				}
			});

			$( '.wp-block-image img' ).each(function() {
				if ( isScrolledIntoView(this) === true ) {
					$(this).addClass( 'animatetl' );
				}
			});
			$( '.wp-block-media-text__media img' ).each(function() {
				if ( isScrolledIntoView(this) === true ) {
					$(this).addClass( 'animatetl' );
				}
			});
			/* $( 'h2.c-heading' ).each(function() {
				if ( isScrolledIntoView(this) === true ) {
					$(this).addClass( 'animatelt' );
				}
			});
			$( 'h3.c-heading' ).each(function() {
				if ( isScrolledIntoView(this) === true ) {
					$(this).addClass( 'animatelt' );
				}
			});
			$( 'section.c-link' ).each(function() {
				if ( isScrolledIntoView(this) === true ) {
					$(this).addClass( 'animatetl' );
				}
			}); */

		});

	});

	function animateCSS(element, animationName, callback) {
		const node = document.querySelector(element)
		node.classList.add('animatetl', animationName)
		node.classList.add('animatelt', animationName)

		function handleAnimationEnd() {
				node.classList.remove('animatetl', animationName)
				node.classList.remove('animatelt', animationName)
				
				node.removeEventListener('animationend', handleAnimationEnd)

				if (typeof callback === 'function') callback()
		}

		node.addEventListener('animationend', handleAnimationEnd)
	}

})( jQuery, window, document );
